import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  scrollTop: number = 0
  threshold = 80

  getScrollTop() {
    return window.pageYOffset || document.documentElement.scrollTop
  }

  connect() {
    this.scrollTop = this.getScrollTop()
  }

  onScroll() {
    const st = this.getScrollTop()
    if (st < this.scrollTop) {
      this.element.classList.remove(`-translate-y-20`)
      this.element.classList.add(`-translate-y-0`)
    } else if (st > this.scrollTop && st > this.threshold) {
      this.element.classList.add(`-translate-y-20`)
      this.element.classList.remove(`-translate-y-0`)
    }
    this.scrollTop = st <= 0 ? 0 : st
  }
}
