import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [`slide`]
  declare readonly slideTargets: HTMLElement[]

  static values = { duration: Number }
  declare readonly durationValue: number

  static classes = [`active`, `inactive`]
  declare readonly activeClasses: string[]
  declare readonly inactiveClasses: string[]

  slideIndex: number = 0
  interval: number

  connect() {
    this.interval = setInterval(this.nextSlide.bind(this), this.durationValue)
  }

  disconnect() {
    clearInterval(this.interval)
  }

  get lastSlideIndex() {
    return this.slideTargets.length - 1
  }

  private nextSlide() {
    this.slideIndex = this.slideIndex === this.lastSlideIndex ? 0 : this.slideIndex + 1
    this.slideTargets.forEach((el, index) => {
      const { classList } = el
      if (index === this.slideIndex) {
        classList.add(...this.activeClasses)
        classList.remove(...this.inactiveClasses)
      } else {
        classList.remove(...this.activeClasses)
        classList.add(...this.inactiveClasses)
      }
    })
  }
}
