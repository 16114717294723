import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    cssClasses: Array,
    threshold: { type: Number, default: 0.3 },
    delay: Number,
  }
  declare readonly cssClassesValue: string[]
  declare readonly thresholdValue: number
  declare readonly delayValue: number

  observer: IntersectionObserver | null = null

  connect() {
    if (this.observer) {
      this.observer.disconnect()
    }
    this.createIntersectionObserver()
  }

  disconnect() {
    this.observer?.unobserve(this.element)
  }

  get threshold() {
    if (window.innerWidth < 650) {
      return 0
    } else {
      return this.thresholdValue
    }
  }

  createIntersectionObserver() {
    this.observer = new IntersectionObserver(this.animate.bind(this), { threshold: this.thresholdValue })
    this.observer.observe(this.element)
  }

  animate(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        return
      }
      const timeout = (this.delayValue || 0) * 1000
      setTimeout(() => {
        entry.target.classList.remove(...this.cssClassesValue)

        this.observer?.unobserve(this.element)
      }, timeout)
    })
  }
}
