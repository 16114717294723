import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  scrollTo(e: Event) {
    const anchor = (e.currentTarget as HTMLAnchorElement)
    if (window.location.pathname !== (new URL(anchor.href).pathname)) {
      return
    }
    e.preventDefault()
    const hash = anchor.hash
    document.querySelector(hash)?.scrollIntoView()
  }
}
