import { Application } from "@hotwired/stimulus"
import HeaderController from "./header_controller"
import ScrollToController from "./scroll_to_controller"
import IntersectionObserverController from "./intersection_observer_controller"
import SectionController from "./section_controller"
import CarouselController from "./carousel_controller"
import NavController from "./nav_controller"
import RichTextController from "./rich_text_controller"

const Stimulus = Application.start()
Stimulus.register("header", HeaderController)
Stimulus.register("scroll-to", ScrollToController)
Stimulus.register("intersection-observer", IntersectionObserverController)
Stimulus.register("section", SectionController)
Stimulus.register("carousel", CarouselController)
Stimulus.register("rich-text", RichTextController)
Stimulus.register("nav", NavController)
