import { Controller } from "@hotwired/stimulus"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import { Document, BLOCKS } from "@contentful/rich-text-types"

export default class extends Controller {
  static values = {
    content: Object,
    assets: Object,
  }
  declare readonly contentValue: Document
  declare readonly assetsValue: object
  options: object

  connect() {
    this.options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
          const id = node.data.target.sys.id
          const url = this.assetsValue[id]
          return `<img class="w-1/2 mx-auto" src="${url}"/>`
        },
      },
    }
    this.element.innerHTML = documentToHtmlString(this.contentValue, this.options)
  }
}
