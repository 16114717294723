import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [`container`, `image`, `wrap`]
  declare readonly containerTarget: HTMLDivElement
  declare readonly wrapTarget: HTMLDivElement
  declare readonly imageTarget: HTMLImageElement

  connect() {
    if (this.imageTarget.height === 0) {
      this.imageTarget.onload = () => {
        this.resize()
      }
    } else {
      this.resize()
    }
  }

  resize() {
    if (window.innerWidth < 640) { return }

    this.containerTarget.style.height = `${this.imageTarget.height}px`
    this.wrapTarget.style.width = `${this.imageTarget.width}px`
  }
}
