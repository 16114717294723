import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [`toggle`, `menu`]
  declare readonly menuTarget: HTMLDivElement
  declare readonly toggleTargets: HTMLElement[]

  show() {
    document.body.classList.add(`overflow-hidden`)
    this.menuTarget.classList.remove(`-translate-y-full`)
    this.menuTarget.classList.add(`top-[100px]`)
    this.toggle()
  }

  hide() {
    document.body.classList.remove(`overflow-hidden`)
    this.menuTarget.classList.add(`-translate-y-full`)
    this.menuTarget.classList.remove(`top-[100px]`)
    this.toggle()
  }

  toggle() {
    this.toggleTargets.forEach(el => {
      el.hidden = !el.hidden
    })
  }
}
